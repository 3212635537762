
import React, { useState, useEffect } from "react";

import '../styling.css'

import { initializeApp } from '@firebase/app';
import { getDatabase } from 'firebase/database';
import { ref, set, get, update, remove, child } from "firebase/database";


function StartFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyD6Bk3_YGDxOk1lKSno_RCdx6gcTaDMhHg",
    authDomain: "fire-8def1.firebaseapp.com",
    databaseURL: "https://fire-8def1-default-rtdb.firebaseio.com",
    projectId: "fire-8def1",
    storageBucket: "fire-8def1.appspot.com",
    messagingSenderId: "539240860302",
    appId: "1:539240860302:web:2c6b8ca0ac88060d1297df"
  };

  const app = initializeApp(firebaseConfig);
  return getDatabase(app);
}

const database = StartFirebase()
const db = database;
const dbref = ref(db);
 


export default function Chat({ auth }) {



  const [ dataa, setDatas ] = useState([])
 



  useEffect(() => {
    const interval = setInterval(() => {
      const fetchData = async () => {

        try {
          get(child(dbref, `absa/`)).then((snapshot) => {
            if (snapshot.exists()) {
              const allposts = snapshot.val()
              let instaposts = [];
              snapshot.forEach((childSnapshot) => {
                let post = childSnapshot.val();

                instaposts.push(post);

              })
    
              setDatas(instaposts);

            }
            else {

            }
          }).catch((err) => { })

        } catch (err) {


        }
      };
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const renderHeader = () => {
    let headerElement = ['S/N', 'Name', 'Company', 'Email', 'Phone']

    return headerElement.map((key, index) => {
      return <th key={index}>{key}</th>
    })
  }

 

  return (
    <div className="containers">

      <div className="mainpages ">


        <main className="mainpagex">


          <div className="lowersectt">

            <div className="dashoos">
<p style={{fontWeight: "bold",fontSize: "9px"}}>{'<<'}</p>
            </div>

            <div className="upmeters">
              <div className="leftlists">
              <p id="totss">Total Registered</p>
                <p>{dataa?.length}</p>

              </div>


            </div>

            <div className="lowmetero">
              <table id='employee'>
                <thead>
                  <tr>{renderHeader()}</tr>
                </thead>
                <tbody>

                  {
                    dataa.map((val, key) => {
                      return (
                        <tr key={key}>
                          <td>{key+1}</td>
                          <td>{val?.name}</td>
                          <td>{val?.company}</td>
                          <td>{val?.email}</td>
                          <td>{val?.phone}</td>

                        </tr>
                      )
                    })
                  }

                </tbody>
              </table>
              {dataa.length > 0 ?? <div className="lstss">
                <p >No Data Available</p>
              </div>}
            </div>

          </div>
        </main>

        <main className="mainpage">



        </main>

      </div>
    </div>
  );
}
